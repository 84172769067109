import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>VenHuizen Family Books</h1>
      <p>A personal app for tracking our books.</p>
    </div>
  );
}

export default App;
